@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import "react-toastify/dist/ReactToastify.css";

:root {
  --primary-color: #990391;
  --secondary-color: #ffa000;
  --grey-color: #757575;
  --light-color: #f5f5f7;
  --white-color: #ffffff;
  --black-color: #000000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  overflow: hidden;
  /* background-color: #ffffff !important; */
}

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d2d2d2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

[placeholder] {
  text-overflow: ellipsis;
}

a {
  text-decoration: none;
}

.modal-header {
  font-size: 18px !important;
  font-weight: bold !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important;
  background-color: transparent !important;
  transition: background-color 9999s ease-in-out 0s;
}

.positionRelative {
  position: relative;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-3 {
  z-index: 3;
}
.z-index-4 {
  z-index: 4;
}
.z-index-5 {
  z-index: 5;
}
.z-index-6 {
  z-index: 6;
}
.z-index-7 {
  z-index: 7;
}
.z-index-8 {
  z-index: 8;
}
.z-index-9 {
  z-index: 9;
}
.z-index-10 {
  z-index: 10;
}

.danger {
  color: #d01d23;
}

.cursor-pointer {
  cursor: pointer !important;
}
.cursor-help {
  cursor: help !important;
}

.m-4 {
  margin: 4 !important;
}
.mt-4 {
  margin-top: 4px !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.ml-4 {
  margin-left: 4px !important;
}
.mr-4 {
  margin-right: 4px !important;
}

.p-4 {
  padding: 4 !important;
}
.pt-4 {
  padding-top: 4px !important;
}
.pb-4 {
  padding-bottom: 4px !important;
}
.pl-4 {
  padding-left: 4px !important;
}
.pr-4 {
  padding-right: 4px !important;
}
.full-Width-card {
  width: 100%;
  height: 100%;
}
.dashedBorder {
  border-style: dashed !important;
  color: #e0e0e0;
}

.titleWrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.twoLineEllipse {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.responsiveMenuIcon {
  display: none !important;
}
.search_input input {
  padding: 0;
}

.disableBtn:disabled {
  cursor: not-allowed !important;
  background: rgba(0, 0, 0, 0.26) !important;
  color: #fff !important;
  /* pointer-events: unset !important; */
}
.outlineDisableBtn:disabled {
  cursor: not-allowed !important;
  /* pointer-events: unset !important; */
}
.outlineDisableBtn:disabled svg {
  color: rgba(0, 0, 0, 0.26);
}
.iconDisableBtn:disabled svg {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  color: rgba(0, 0, 0, 0.26) !important;
  pointer-events: unset !important;
}

/* .tablePagination {
  padding: 2px 16px;
  background-color: #2b6aaa;
  color: #fff;
} */
/* .tablePagination .MuiTablePagination-toolbar,
.tablePagination .MuiTablePagination-toolbar svg {
  color: #fff;
} */
/* .tablePagination .MuiTablePagination-toolbar .MuiTablePagination-actions .Mui-disabled > svg {
  color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
} */

.pro-sidebar > .pro-sidebar-inner {
  background: unset;
}

.image {
  object-fit: cover;
  max-width: 100%;
}
.imageContain {
  object-fit: contain;
  max-width: 100%;
}

.modal-header {
  font-size: 24px !important;
  /* color: #158582; */
}
.mobModalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.pageMainCardBox,
.cardTable {
  padding: 2rem;
}

.hideInDesktop,
.hideInDesktop991 {
  display: none !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 14px !important;
}
.MuiTooltip-popper .MuiTooltip-arrow {
  left: 50% !important;
  transform: translate(-50%, 0) !important;
}
.inputStyling {
  font-size: 1rem;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 8.5px 14px;
  color: gray;
  transition: 0.1s ease-out;
  width: 100%;
}

.inputCustomLabels {
  position: absolute;
  top: -8px;
  left: 9px;
  font-size: 12px;
  padding: 0 5px;
}

.fileCustomLabels {
  position: absolute;
  top: -20%;
  right: 2%;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: #f5f5f7;
  padding: 0 5px;
}
/* 
button:disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
  pointer-events: auto !important;
} */

.grayBg {
  background: #f9f9f9;
}

.inputCustomLabels-1 {
  position: absolute;
  top: -8px;
  left: 9px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: #f9f9f9;
  padding: 0 5px;
}
.inputCustomLabels-2 {
  position: absolute;
  top: -8px;
  left: 9px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: #eeeeee;
  padding: 0 5px;
}

.notifyCheckbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.notifyMenu ul {
  max-width: 320px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #1d9142;
  fill: none;
  animation: stroke 1.2s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #1d9142;
  animation: fill 0.8s ease-in-out 0.8s forwards, scale 0.6s infinite ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.6s infinite cubic-bezier(0.65, 0, 0.45, 1) 1.6s forwards;
}

.checkmark__cross {
  stroke: #d01d23;
  fill: #d01d23;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 50px #1d9142;
  }
}

.animateSuccessTip {
  animation: animateSuccessTip 1.5s infinite ease-in;
}
.animateSuccessLong {
  animation: animateSuccessLong 1.5s infinite ease-in;
}
.f-modal-icon.f-modal-success.animate:after {
  animation: rotatePlaceholder 4.25s ease-in;
}
.f-modal-icon.f-modal-error.animate:after {
  animation: rotatePlaceholder 4.25s ease-in;
}
.animateErrorIcon {
  animation: animateErrorIcon 0.5s infinite;
}
.animateXLeft {
  animation: animateXLeft 1.5s infinite ease-in;
}
.animateXRight {
  animation: animateXRight 1.5s infinite ease-in;
}
.scaleWarning {
  animation: scaleWarning 0.75s infinite alternate;
}
.pulseWarningIns {
  animation: pulseWarningIns 0.75s infinite alternate;
}
@keyframes animateSuccessTip {
  0%,
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 27px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 35px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessLong {
  0%,
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 67px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 59px;
    right: 8px;
    top: 38px;
  }
}
@keyframes rotatePlaceholder {
  0%,
  5% {
    transform: rotate(-45deg);
  }
  100%,
  12% {
    transform: rotate(-405deg);
  }
}
@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes animateXLeft {
  0%,
  65% {
    left: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    left: 14px;
    top: 47px;
    width: 66px;
  }
  100% {
    left: 17px;
    top: 48px;
    width: 66px;
  }
}
@keyframes animateXRight {
  0%,
  65% {
    right: 82px;
    top: 95px;
    width: 0;
  }
  84% {
    right: 14px;
    top: 47px;
    width: 66px;
  }
  100% {
    right: 16px;
    top: 48px;
    width: 66px;
  }
}
@keyframes scaleWarning {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulseWarning {
  0% {
    background-color: #fff;
    transform: scale(1);
    opacity: 0.5;
  }
  30% {
    background-color: #fff;
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    background-color: #f8bb86;
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes pulseWarningIns {
  0% {
    background-color: #f8d486;
  }
  100% {
    background-color: #f8bb86;
  }
}

.respTable {
  overflow: auto;
}
.respTable table {
  min-width: max-content;
}

.customTextarea {
  padding: 16px 50px 16px 16px;
  border-color: #d0d7de;
  border-radius: 4px;
  resize: vertical;
  min-height: 50px;
  width: 100%;
}
.attachFileBtn {
  border: 1px solid #d0d7de;
  background-color: #d0d7de;
  border-radius: 50%;
  padding: 4px;
  transition: 0.5s all;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.attachFileBtn:hover {
  background-color: #253b74;
  border-color: #253b74;
}
.attachFileBtn svg {
  transform: rotate(-45deg);
  transition: 0.5s all;
}
.attachFileBtn:hover svg {
  color: #d0d7de;
}
.chatImgDeleteBtn {
  background: #e9f4ff !important;
  border-radius: 50% !important;
  padding: 4px !important;
  position: absolute !important;
  top: 4px;
  right: 4px;
  z-index: 2;
  transition: 0.5s all;
}
.chatImgDeleteBtn:hover {
  background-color: #e0e0e0 !important;
}
/* input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.decryptTextarea {
  padding: 16px;
  border-color: #d0d7de;
  border-radius: 4px;
  resize: vertical;
  min-height: 50px;
  width: 100%;
}

.searchBar {
  max-width: 400px;
}

/* Media queries */
/* Tablets */
@media (max-width: 1200px) {
  .cardTable {
    padding: 0;
  }
}
@media (max-width: 991px) {
  .sectionPad {
    padding: 8px !important;
  }
  .respBottomSpace {
    margin-bottom: 48px !important;
  }

  .MuiDialog-container .MuiPaper-root {
    margin: 5px !important;
  }
  .responsive-head {
    width: 100%;
    justify-content: space-between !important;
  }
  
}
@media screen and (max-width:899px) {
  .responsiveMenuIcon {
    display: flex !important;
  }
}

@media (max-width: 768px) {
  .app .btn-toggle {
    display: inline-flex;
  }
  .hideInResponsive {
    display: none !important;
  }
  .showInResponsive {
    display: block !important;
  }
  .searchBar {
    width: 100%;
    max-width: none !important;
  }
}

/* Mobile phones */
@media (max-width: 599px) {
  .pageMainCardBox > .MuiPaper-root {
    padding: 15px !important;
  }
  .cardTable > .MuiPaper-root {
    padding: 4px !important;
  }
  .MuiDialogContent-root {
    padding: 10px !important;
  }
}

@media (max-width: 575px) {
  .pageMainCardBox {
    padding: 0.5rem;
  }
  .cardTable {
    padding: 0;
  }

  .emcc-4,
  .emcc-5,
  .emcc-6,
  .emcc-7 {
    width: 50%;
  }
}

.dragDropFile {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.dragDropFile input {
  position: absolute;
  top: 0;
  right: 0;
  width: 200%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

.disabledPassEyeIcon:disabled {
  opacity: 0.5;
}

.label-blue,
.label-red,
.label-green,
.label-gray,
.label-lightBlue,
.label-orange,
.label-black {
  padding: 2px 12px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 10px;
  text-wrap: nowrap;
}
.label-blue {
  color: #2b6aaa;
  background: rgba(43, 106, 170, 0.2);
}
.label-red {
  color: #d01d23;
  background: rgba(208, 29, 35, 0.2);
}
.label-green {
  color: #1d9142;
  background: rgba(29, 145, 66, 0.2);
}
.label-gray {
  /* color: #3d3d3d; */
  background: rgba(61, 61, 61, 0.2);
}
.label-lightBlue {
  color: #4c8ac7;
  background: rgba(76, 137, 199, 0.2);
}
.label-orange {
  color: #f3863e;
  background: rgba(243, 134, 62, 0.2);
}
.label-black {
  color: #000;
  background: rgba(0, 0, 0, 0.2);
}
.toolSpan {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.datePickerStyling-1 {
  font-weight: 400;
  font-size: 1rem;
  /* color: #333 !important; */
  padding: 8.5px 14px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #c4c4c4;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.treeSubRow {
  width: 100%;
  padding-left: 10px !important;
}
.treeValue {
  display: flex;
  align-items: center;
  gap: 12px;
}

@media (max-width: 575px) {
  .treeSubRow {
    padding-left: 0 !important;
  }
}
.productPhoto {
  position: relative;
  width: 100px;
  margin: 0 !important;
  overflow: auto !important;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.productPhoto img,
.productPhoto video,
.productPhoto svg {
  width: 100%;
  max-width: 100px;
  max-height: 100px;
  display: block;
}
.productPhoto .deletebutton {
  position: absolute;
  top: 0;
  right: 0;
  min-width: auto;
}
.slide {
  display: none;
}
.slide.active-anim {
  display: block;
  height: 300px;
  width: 300px;
}
.slide img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.slider-btn {
  position: absolute !important;
  top: 50%;
}
.slider-btn.next {
  right: 10px;
}
.slider-btn.prev {
  left: 10px;
  transform: rotate(180deg);
}

.svgBg {
  position: relative;
  background-color: var(--secondary-color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 100 100'%3E%3Crect x='0' y='0' width='50' height='50' fill-opacity='0.1' fill='%23990291'/%3E%3C/svg%3E");
}
.svgBg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
}
.svgContent {
  position: relative;
  z-index: 1;
}

.profileThumbImage {
  width: 50px;
  height: 50px;
  padding: 2px;
  border: 1px solid var(--grey-color);
  margin: 2px;
}

/* Custom card class */
.customCard {
  padding: 16px;
  /* background-color: var(--white-color) !important; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

/* Custom Card Title */
.customCardTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.customCardTitle .customCardTitleIcon {
  padding: 6px;
  background-color: var(--light-color);
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  line-height: 0;
}
.customCardTitle .customCardTitleIcon svg {
  width: 20px;
  height: 20px;
  color: var(--secondary-color);
}

/* Custom table class */
.customTable thead tr th {
  background-color: #a3a3a3 !important;
  color: var(--white-color) !important;
  font-weight: 600 !important;
}
.customTable thead tr th:first-child {
  border-radius: 4px 0 0 0;
}
.customTable thead tr th:last-child {
  border-radius: 0 4px 0 0;
}
.customTable tbody tr td {
  background-color: var(--light-color) !important;
}
.customTable tbody tr:last-child td {
  border: none;
}
.customTable tbody tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}
.customTable tbody tr:last-child td:last-child {
  border-radius: 0 0 4px 0;
}
.sticky-row {
  overflow: hidden;
  z-index: 1;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px; */
  background-color: #f5f5f7;
  right: 0px;
}
.slide.active {
  display: block;
}
.slide.active img {
  max-width: 100%;
  max-height: calc(100vh - 64px);
  object-fit: contain;
  display: block;
}
.c-pointer {
  cursor: pointer;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pro-sidebar .MuiButtonBase-root:hover .MuiListItemIcon-root {
  animation: swing ease-in-out 0.5s 1 alternate;
}

.card-svg-wrapper {
  height: 55px;
  width: 55px;
  border-radius: 50px;
  padding: 10px 12px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: #464a53; */
  min-width: 55px;
}

.card-svg-media-body {
  flex: 1;
}

.card-svg-media-body p {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.8;
  margin-bottom: 0.25rem !important;
}
.card-svg-media-body h4 {
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  line-height: 1.2;
  /* color: #3d4465; */
}
@keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
}
@-webkit-keyframes swing {
  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
}
.viewLable {
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.invoiceItem th:nth-child(2) {
  min-width: 338px;
}
.invoiceItem th:nth-child(3) {
  min-width: 144px;
}
.invoiceItem th:nth-child(4) {
  min-width: 144px;
}
.link {
  text-transform: capitalize;
  text-decoration: "none";
  white-space: "nowrap";
  font-weight: "600";
  color: rgb(86, 119, 255);
}
.logtext {
  display: inline-flex;
    flex-direction: column-reverse;
    text-transform: capitalize;
    row-gap: 2px;
    font-size: 16px !important;
    font-weight: 600;
}
table {
  text-wrap: nowrap;
}
.inner_table {
  margin: 20px 30px;
}
.accordianTable tr th ,
.inner_table tr th {
  padding: 7px 7px;
  min-width: unset;
  
}
.accordianTable tbody tr td ,
.inner_table tbody tr td {
  padding: 0px 7px;
  min-width: unset;
}

.alinment {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.collection-text h2 {
  font-weight: 500;
  font-size: 20px;
}
.das-count {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}
.das-count span{
  font-size: 16px;
  font-weight: 600;
}
.das-count h2 {
  font-size: 20px;
}
@media screen and (max-width:479px) {
  .text-wrapper {
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start !important;
  }
} 
@media print {
  body {
    visibility: hidden;
  }
  #section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
  /* .pagebreak {
    page-break-after: always;
  } */
}
.react-datepicker-popper {
  z-index: 99 !important;
}
.collection-text .MuiCardHeader-title {
  font-size: 18px;
  font-weight: 600;
}
@media screen and (max-width:1199px) {
  .order-change {
    flex-direction: column-reverse !important;  
   }
}
@media screen and (max-width:1535px) {
  .chart-size  {
    width: auto !important;
  }
}
.spacetop {
  margin-bottom: 0 !important;
}
.spacetop .collection-text {
  padding-top: 26px;
  padding-bottom: 26px;
  height: 100%;
}
.spacetop .text-wrapper,.verticalgap .text-wrapper{
  flex-direction: column;
  align-items: baseline;
  row-gap: 27px;
}
.space0 .collection-text {
  padding-bottom: 0;
  padding-top: 0;
}
.distri-space .das-count {
  padding-top: 36px;
  padding-bottom: 36px;
  gap: 26px;
}
.card-svg-media-body p {
  text-wrap: nowrap;
}
.editicone {
  position: absolute !important;
  border-radius: 0 !important;
  /* background-color: #dfdddd !important; */
    height: 82px;
    top: -1px;
    right: 0;
    z-index: 99;
}

@media screen and (max-width:1535px) {
  .aaa {
    order: 1;
    }
    .pppp {
      order: 2;
    }
}

/* .payment-amount .text-wrapper {
  
} */





.settingdate .react-datepicker__month-container {
  border: none;
}

.settingdate .react-datepicker {
  border: none;
  box-shadow: 0 0 4px #000000c4;
}

.settingdate .react-datepicker__header {
  border: none;
  background: #e9e9e9;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.settingdate  button.react-datepicker__navigation {
  filter: invert(1);
  left: 213px;
  top: 7px;
}

.settingdate .react-datepicker__header__dropdown {display: flex;justify-content: space-between;}

.settingdate .react-datepicker__month-dropdown-container {
  border: 1px solid #ddd;
  width: 50%;
  margin: 0;
  padding: 8px 0px;
}

.settingdate .react-datepicker__year-dropdown-container {
  margin: 0;
  width: 50%;
  border: 1px solid #ddd;
  padding: 8px 0px;
}

.settingdate span.react-datepicker__month-read-view--down-arrow {
  right: 14px;
  filter: invert(1);
}

.settingdate span.react-datepicker__year-read-view--down-arrow {
  filter: invert(1);
  right: 14px;
}
.settingdate .react-datepicker__header.react-datepicker__header--has-time-select {
  background: #fff;
}

.settingdate ul.react-datepicker__time-list {
  background: #e9e9e9;
}
.settingdate .react-datepicker__current-month {
  display: flex;
  padding-left: 10px;
}
.settingdate .react-datepicker__navigation-icon--previous {
  right: 8px;
}
.settingdate .react-datepicker__navigation-icon--next {
left: 8px;
}
.settingdate .react-datepicker__month-container {
  padding: 6px;
}
.videosize {
  width: 190px ;
  display: block;
}
.videosize video {
  max-width: 190px ;
}

.apexcharts-tooltip {
  background: #f3f3f3;
  color: rgb(127, 100, 226);
}
.header-space th.MuiTableCell-head {
  padding: 8px 16px;
}
.header-space {
  overflow-y: auto;
  max-height: 173px;
}
.placestyle span {
  color: #918a8a !important;
}
.list-title h2{
  padding: 7px 16px;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 1px solid #ddd;
  border-radius: 12px 12px 0px 0px;
}
@media screen and (max-width:479px) {
  .adminDrawer {
    width: 100% !important;
  }
}
.my-active-class {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}
.css-6hp17o-MuiList-root-MuiMenu-list{
  max-height: 200px;
}
.record_table tbody tr:last-child td ,.case_table tbody tr:last-child td {
  border-bottom: none;
}
.case_table tfoot tr:last-child td ,.deposit_table tfoot tr:last-child td{
  border-bottom: none;
  font-weight: 600;
}
.case_table thead tr th,.case_table tbody tr td,.case_table tbody tr th {
  font-weight: 600;
  font-size: medium;
}
.Withdrow,.deposit {
  background: #782a19;
  padding: 1px 7px;
  border-radius: 23px;
  font-size: 13px; 
  color: #fff;
}
.deposit {
  background: #22702c;
}
select.select-filter {
  padding: 7px 8px;
  border-radius: 4px;
  font-size: 15px;
  text-transform: capitalize;
  margin: 10px;
  appearance: none;
  width: 134px;
  background-image: url("../src/media/images/arrow-down.svg");
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: right center;
  background-origin: content-box;
}
.select-filter option {
  background-color: #cccbcb;
  color: #000;
}
.loaction-name {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0px 4px;
  margin-bottom: 30px;
  border-radius: 4px;
}

.loaction-name .MuiTypography-body1 {
  font-size: 20px;
  font-weight: 600;
}
.loaction-name p.MuiTypography-root.MuiTypography-body2 {
  font-size: 16px;
}
.dialog_position .MuiDialog-scrollPaper  {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% ,-50%);
}

.balance-cards .MuiCardContent-root {
  background:linear-gradient(45deg, black, #8f6811) ;
  color: #fff;
}
.weight th {
  font-weight: 700;
}
.remove-border {
  border-top:transparent !important ;
}
.ratiocount > .das-count  {
  flex-direction: row;
  flex-wrap: wrap;
}
.ratiocount > .das-count > .MuiBox-root.css-0 {
  width: calc(33.33% - 10.66px);
}
.height > .das-count {
  height: 100%;
  gap: 30px;
}

@media screen and (max-width:767px) {
  .dialog_position .MuiDialog-scrollPaper {
    width: 100%;
  }
}
@media screen and (max-width:1399px) {
  .fnt-size h2 {
    font-size: 22px;
  }
}
@media screen and (max-width:539px) {
  .MuiDialog-paper {
    width: 100% !important;
  }
  .ratiocount > .das-count > .MuiBox-root.css-0 {
    width: calc(50% - 8px);
  }
  
}

.mergecard > .das-count {
  box-shadow: 3px 3px 3px 0px #d5d5d5;
}
.iconposition svg.cursor-pointer,.position svg.cursor-pointer {
  position: absolute;
  top: 16px;
  right: 0px;
  color: #242121;
  background: #e3e3e3;
  width: 60px;
  height: 40px;
  padding: 8px 0;
  border-radius: 4px;
}
.position svg.cursor-pointer {
  top: 0;
}
.iconposition,.position {
  position: relative;
}
 
.top-sp .collection-text {
  padding-top: 0;
  padding-bottom: 0;
}
.tooltipicon {
  display: flex;
  padding-left: 5px;
}